<template>
  <!-- Login Component -->
  <v-container>
    <v-form v-model="valid" data-cy="form">
      <v-card color="primary">
        <v-card-title>
          <pagetitle icon="mdi-login" title="Login"></pagetitle>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy="username"
                required
                label="Username"
                prepend-icon="mdi-account-circle"
                v-model="user.userName"
                :rules="userNameRules"
              />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
              <v-text-field color="accent"
                data-cy="password"
                label="Password"
                v-model="user.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="passwordRules"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              data-cy="login-btn"
              outlined
              :disabled="!valid"
              @click="onLogin"
            >
              <v-icon left>mdi-login</v-icon>Login
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              data-cy="reset-btn"
              outlined
              :disabled="LoggedIn"
              @click="onResetPassword"
              ><v-icon left>mdi-lock-reset</v-icon>Reset Password</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              data-cy="forgot-btn"
              outlined
              :disabled="LoggedIn"
              @click="onForgotUsername"
              ><v-icon left>mdi-account-question-outline</v-icon>Forgot
              Username</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              data-cy="register-btn"
              outlined
              :disabled="LoggedIn"
              @click="onRegister"
              ><v-icon left>mdi-account-plus-outline</v-icon>Register</v-btn
            >
            <v-spacer></v-spacer>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
    <div>
      <status ref="alert" @closed="onStatusAlertClosed"></status>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import pagetitle from "../../components/controls/Title"
import router from "@/router"
import status from "../../components/controls/Status"
import store from "@/store"
import loggedInMixin from "../../mixins/loggedInMixin"
import breakpointMixin from "../../mixins/breakpointMixin"
import userService from "@/_services/user-service"

export default {
  name: "Account-Login",

  components: {
    pagetitle,
    status,
  },

  created() {
    this.returnUrl = this.$route.query.returnUrl || "/";
  },

  data: () => ({
    passwordRules: [(v) => !!v || "Password is required"],
    returnUrl: "",
    showPassword: false,
    user: {
      userName: "",
      password: "",
    },
    userNameRules: [(v) => !!v || "Username is required"],
    valid: false,
  }),

  mixins: [breakpointMixin, loggedInMixin],

  methods: {
    onForgotUsername() {
      router.push("/account/forgot");
    },

    onLogin() {
      const url = "account";
      apiService.post(url, this.user, this.loginSuccess, this.loginError);
    },

    loginSuccess(result) {
      console.log(result);
      const notification = {
        title: "Login",
        status: "success",
        messages: ["User login successful"],
      };
      store.commit("setNotification", notification);
      store.commit("setReturnUrl", this.$route.query.returnUrl);
      userService.loginSuccess(result);
      this.LoggedIn = true;
    },

    loginError(error) {
      const notification = {
        title: "Login",
        error: error.data,
      };
      store.commit("setResponseError", notification);
    },

    getRoles() {
        userService.getUserRoles()
    },

    onRegister() {
      router.push("/account/register");
    },
    onResetPassword() {
      router.push("/account/reset");
    },

    onStatusAlertClosed(statusCode) {
      if (statusCode === 200) {
        router.push("/");
      }
    },
  },
  router: router,
  store: store,

  watch: {
    "$store.state.notificationDisplay": function () {
      const display = this.$store.getters.getNotificationDisplay;
      if (!display) {
        this.getRoles()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-right: 15px;
}
.v-btn {
  margin-bottom: 20px;
  margin-left: 10px;
}
</style>
